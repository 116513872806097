import "./Footer.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer__container">
        <h2 className="footer__title">
          Designed & Created By: <br /> Ralph Cabauatan © {currentYear}
        </h2>
      </div>
    </div>
  );
};

export default Footer;
